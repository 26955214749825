.regulation-list {
  clear: both;
  list-style: none;
  padding-left: 34px;

  li {
    padding-top: 35px;
    padding-left: 35px;
    display: block;
    position: relative;
    counter-increment: inst;
    font-size: 24px;

    @media screen and (max-width: $lg) {
      font-size: 20px;
    }

    @media screen and (max-width: $sm) {
      font-size: 16px;
      padding-left: 0;
    }

    &::before {
      content: counter(inst);
      background: $color-red;
      color: #fff;
      font-size: 19px;
      font-weight: 700;
      border-radius: 50%;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
      left: -35px;
      top: 32px;
      height: 35px;
      width: 35px;
      position: absolute;
      z-index: 2;

      @media screen and (max-width: $sm) {
        width: 25px;
        height: 25px;
        font-size: 16px;
      }
    }
  }
}
