$color-red: #DE5E52;
$color-orange: #F29F62;
$color-brown: #ECCF9C;
$color-light: #FCF1DC;

.color-red {
  color: $color-red;
}

// sizes
$xs: 575px;
$sm: 767px;
$md: 992px;
$lg: 1200px;
$xl: 1680px;