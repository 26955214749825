.consultation {
  margin-top: 5rem;

  &__inputs-container {
    max-width: 450px;

    @media screen and (max-width: $sm) {
      max-width: 100%;
    }
  }

  &__warning {
    padding: 20px;
    background-color: $color-red;
    color: #fff;
    text-align: center;
    border-radius: 20px;
    font-weight: 700;
  }

  &__legend {
    background-color: #2A1918;
    padding: 20px;
    border-radius: 20px;
    width: 100%;
    max-width: 440px;

    p {
      color: #fff;
      font-weight: 700;
    }

    &-disable , &-occupied, &-chosen {
      display: flex;
      align-items: center;

      span {
        width: 40px;
        height: 15px;
        display: inline-block;
      }
    }

    &-disable {
      margin-bottom: 15px;
      span {
        background-color: $color-red;
      }
    }

    &-occupied {
      margin-bottom: 15px;
      span {
        background-color: $color-brown;
      }
    }

    &-chosen {
      span {
        background-color: $color-orange;
      }
    }
  }

  .check{
    position: relative;
    margin-bottom: 20px;
    @extend .f16;
    color: #000;
    input{
      width: 1px;
      height: 1px;
      position: absolute;
      top: 100%;
      left: 10%;
      opacity: 0;
      & + label{
        display: block;
        text-align: left;
        padding-left: 30px;
        transition: .2s;
        a{
          font-weight: 700;
          color: #000;
          &:hover{
            color: #000;
            text-decoration: underline;
          }
        }
        span{
          box-sizing: content-box;
          width: 16px;
          height: 16px;
          display: block;
          border-radius: 1px;
          border: 2px solid $color-orange;
          position: absolute;
          top: 0;
          left: 0;
          &::before{
            content: "";
            width: 10px;
            height: 10px;
            display: block;
            border-radius: 1px;
            position: absolute;
            background-color: transparent;
            top: 3px;
            left: 3px;
            transition: .2s;
          }
        }
      }
      &.error + label span {
        border: 2px solid red;
      }
      &:checked + label{
        span{
          &::before{
            background-color: $color-orange;
          }
        }
      }
      &[disabled] + label{
        opacity: .6;
      }
    }
  }

  .input {
    height: 60px;
    width: 100%;
    padding: 20px;
    border: 1px solid $color-orange;
    background-color: #fff;
    border-radius: 30px;
    display: flex;
    align-items: center;

    &.text-area {
      height: 100%;
      border-radius: 20px;
    }
    &.multiselection {
      min-height: 60px;
      height: 100%;
      padding: 0 20px;
    }
    .divider {
      width: 1px;
      height: 100%;
      background-color: #E1E1E1;
      margin-left: 10px;
    }
    .icon {
      width: 100%;
      max-width: 21px;
    }

    p {
      font-size: 16px;
    }
  }

  &__input {
    border: none;
    padding: 15px 10px;
    font-size: 16px;
    width: 100%;
  }

  &__phone-prefix {
    width: 20px;
    position: relative;
    padding: 15px 0 15px 0;
  }

}

.vdp-datepicker__calendar {
  width: 100% !important;
  max-width: 440px !important;
  background-color: #2A1918 !important;
  border: none !important;
  border-radius: 20px;
  padding: 10px !important;

  @media screen and (max-width: $sm) {
    max-width: 100% !important;
  }
}
.vdp-datepicker__calendar .cell:not(.blank):not(.disabled).day:hover, .vdp-datepicker__calendar .cell:not(.blank):not(.disabled).month:hover, .vdp-datepicker__calendar .cell:not(.blank):not(.disabled).year:hover{
  border: none !important;
  border-radius: 0 !important;
  background-color: transparent !important;
  //color: #fff !important;
}

.vdp-datepicker__calendar .cell {
  position: relative !important;
  z-index: 2 !important;
}

.vdp-datepicker__calendar .cell:not(.day-header):not(.blank):not(.disabled):hover {
  &::before {
    content: '' !important;
    width: 60% !important;
    height: 90% !important;
    //background-color: $color-orange !important;
    position: absolute !important;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border: 3px solid rgba(242, 159, 98, 0.7) !important;
    border-radius: 50%;
    z-index: -1;
  }
}

.vdp-datepicker__calendar .cell.disabled::before {
  content: '' !important;
  width: 60% !important;
  height: 90% !important;
  background-color: $color-red !important;
  position: absolute !important;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  z-index: -1;
}

.vdp-datepicker__calendar .cell.highlighted::before {
  content: '' !important;
  width: 60% !important;
  height: 90% !important;
  background-color: $color-brown !important;
  position: absolute !important;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  z-index: -1;
}

.vdp-datepicker__calendar .cell.selected::before {
  content: '' !important;
  width: 60% !important;
  height: 90% !important;
  //background-color: $color-orange !important;
  position: absolute !important;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: 3px solid $color-orange !important;
  border-radius: 50%;
  z-index: -1;
}

.vdp-datepicker__calendar .cell.day-header {
  color: #F29F62 !important;
  background-color: #341D1A !important;
}

.vdp-datepicker__calendar .cell {
  color: #fff !important;
  background-color: #2A1918 !important;
  font-size: 16px;
  border: 1px solid #201311;
}

.vdp-datepicker__calendar .cell.highlighted {
  color: #000 !important;
}

.vdp-datepicker__calendar .day__month_btn.up, .vdp-datepicker__calendar .month__year_btn.up, .vdp-datepicker__calendar .prev ~ span  {
  background-color: #402926 !important;
  color: #fff !important;
  text-transform: uppercase !important;
  font-weight: bold !important;
  font-size: 18px;
}

.vdp-datepicker__calendar .next, .vdp-datepicker__calendar .prev {
  background-color: #402926 !important;
}

.vdp-datepicker__calendar .next {
  border-top-right-radius: 20px;
  &::after {
    content: '';
    background: url("assets/arrow-calendar-right.svg") !important;
    background-repeat: no-repeat !important;
    border: none !important;
    width: 21px;
    height: 21px;
  }
}

.vdp-datepicker__calendar .prev {
  border-top-left-radius: 20px;
  &::after {
    content: '';
    background: url("assets/arrow-calendar-left.svg") !important;
    background-repeat: no-repeat !important;
    border: none !important;
    width: 21px;
    height: 21px;
  }
}

.vdp-datepicker__calendar .day__month_btn.up {
  pointer-events: none
}

.multiselect {
  border: none;
  color: #000;

  &__single {
    color: #000;
  }

  &__tags {
    border: none;
    padding: 9px 40px 0 9px;
  }

  &__tag {
    background: $color-orange;
    font-weight: 700;
  }

  &__option--highlight {
    background: $color-orange;
  }

  &__option--highlight::after {
    content: '';
    background: $color-orange;
  }

  .multiselect__tag-icon:after {
    color: #fff;
  }

  .multiselect__tag-icon:focus, .multiselect__tag-icon:hover {
    background: $color-red;
  }

  .multiselect__option--selected.multiselect__option--highlight {
    background: $color-red;

    &::after {
      background: $color-red;
      content: 'Kliknij aby usunąć'
    }
  }

  .multiselect__option--selected:after {
    content: 'Zaznaczone'
  }
}