.navigation-container {
  position: relative;
  z-index: 10;

  .navigation {
    position: fixed;
    background-color: $color-orange;
    padding: 8rem 0 5rem 0;
    height: 100vh;
    width: 27vw;
    top: 0;
    right: 0;


    @media screen and (max-width: $xl) {
      padding: 1rem 0;
    }

    @media screen and (max-width: $lg) {
      width: 100vw;
      pointer-events: none;
    }


    &__logo {
      width: 100%;
      max-width: 263px;
      margin-bottom: 24px;
      padding: 0 4rem;

      @media screen and (max-width: $xl) {
        max-width: 200px;
      }
    }

    &__nav {
      padding-left: 0;
    }

    &__nav-li {
      list-style-type: none;
      padding: 1px 0 0 4rem;
      position: relative;
      margin: 5px 0;

      &.active {
        &::before {
          content: '';
          width: 6px;
          height: 100%;
          background-color: $color-light;
          position: absolute;
          top: 0;
          left: 0;
        }

        .navigation__nav-link {
          color: $color-light;
        }
      }
    }

    &__nav-link {
      color: #000;
      text-decoration: none;
      transition: all .3s;

      &:hover {
        color: $color-light;
      }
    }

    &__consultation-link {
      a {
        color: #B13F3F;

        &:hover {
          color: $color-light;
        }
      }
    }

    &__divider {
      width: 37px;
      height: 1px;
      background-color: #000;
      margin: 2rem 0 2rem 4rem;
    }

    &__icons {
      margin: 2rem 0 2rem 4rem;
    }

    &__icon {
      text-decoration: none;
      margin-right: 1rem;
      svg {
        path {
          transition: all 0.3s;
        }
      }

      &:hover {
        svg {
          path {
            fill: $color-light;
          }
        }
      }
    }

    &__slider {
      margin-left: 4rem;
    }

    &__slider-slide {
      max-width: 230px;
      width: 100%;
      height: 100%;
    }

    .my-autoplay-controls {
      .my-toggle-button {

      }
    }
  }

  .hamburger{
    width: 40px;
    height: 19px;
    z-index: 111;
    cursor: pointer;
    display: none;
    position: relative;
    transition: .4s ease-out;
    span{
      width: 100%;
      height: 2px;
      background: #594B49;
      display: block;
      position: absolute;
      left: 0;
      transition: .4s ease-out;
      &:nth-child(1){
        top: 0;
      }
      &:nth-child(2){
        top: 8px;
      }
      &:nth-child(3){
        top: 16px;
      }
    }
  }

  &.active{
    .navigation {
      pointer-events: auto;
    }

    .hamburger span{
      &:nth-child(1){
        transform: rotate(45deg) translate(8px, 4px);
      }
      &:nth-child(2){
        width: 0;
        transform: translate(-18px, 0);
        opacity: 0;
      }
      &:nth-child(3){
        transform: rotate(-45deg) translate(8px, -3px);
      }
    }
  }

  @media screen and (max-width: $lg) {
    position: fixed;
    top: 25px;
    right: 25px;

    .navigation{
      overflow: auto;
      width: 100vw;
      height: 100vh;
      padding-top: 20px;
      background: $color-orange;
      position: fixed;
      margin: 0 auto;
      top: 0;
      right: 0;
      opacity: 0;
      transform: scale(3);
      transform-origin: 50% 50%;
      visibility: hidden;
      transition: .4s ease-out;
      //ul{
      //  width: 100%;
      //  position: absolute;
      //  top: 50%;
      //  left: 0;
      //  transform: translate(0, -50%);
      //  li{
      //    display: block;
      //    text-align: center;
      //    a{
      //      background: transparent;
      //      padding: 20px;
      //      @media (orientation: landscape) {
      //        padding: 10px 20px;
      //      }
      //      display: inline-block;
      //      text-align: center;
      //    }
      //  }
      //}
    }
    .hamburger{
      display: block;
    }
    &.active{
      .navigation{
        opacity: 1;
        visibility: visible;
        transform: scale(1);
      }
    }
  }
}
