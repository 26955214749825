.blog {
  .blog-search {
    height: 60px;
    display: flex;

    @media screen and (max-width: $xs) {
      flex-direction: column;
      height: auto;
      margin-bottom: 2rem;
      align-items: center;
      width: 100%;
    }

    &__divider {
      width: 1px;
      background-color: #E1E1E1;
      height: 100%;
      margin: 0 15px;

      @media screen and (max-width: $xs) {
        height: 40px;
      }
    }

    &__search-input {
      border: 1px solid $color-orange;
      border-radius: 50px;
      background-color: #fff;
      color: #979797;
      display: flex;
      margin-right: 2rem;
      align-items: center;
      padding: 10px 25px;

      @media screen and (max-width: $xs) {
        margin-right: 0;
        margin-bottom: 2rem;
        width: 100%;
      }

      input, select {
        color: #979797;
        border: none;
        padding: 15px 0;

        @extend .f16;
      }

      input {
        @media screen and (max-width: $xs) {
          width: 100%;
        }
      }


    }
  }

  .blog-article {
    &__card {
      height: 300px;
      width: 100%;
      position: relative;
      display: block;
    }

    &__card-layout {
      height: 100%;
      border-radius: 20px;
      position: relative;
      z-index: 2;
    }

    &__img {
      background-repeat: no-repeat;
      background-position: center top;
      background-size: cover;
      width: 100%;
      height: 100%;
      border-radius: 20px;
    }

    &__date {
      border-bottom-right-radius: 20px;
      border-top-left-radius: 20px;
      background-color: #000;
      position: absolute;
      top: 0;
      left: 0;
      padding: 5px 15px;
    }

    &__date-day {
      color: #fff;
    }

    &__date-month {
      color: #fff;
    }

    &__title-container {
      position: absolute;
      bottom: 0;
      left: 0;
      color: #fff;
      background: linear-gradient(180deg, rgba(222,94,82,0) 0%, rgba(222,94,82,1) 100%);
      height: 50%;
      width: 100%;
      margin: 0;
      border-bottom-left-radius: 20px;
      border-bottom-right-radius: 20px;
    }

    &__title {
      position: absolute;
      bottom: 0;
      left: 0;
      padding: 25px;
      font-weight: 700;
    }

    &__tags {
      display: flex;
      flex-wrap: wrap;
      margin-top: 2rem;
      margin-bottom: 3rem;
    }

    &__tag {
      margin: 3px;
      border-radius: 5px;
      padding: 5px 10px;
      background-color: $color-red;
      color: #fff;
    }
  }
}

