.metamorphosis-row {
  padding: 4rem 0;
  border-bottom: 2px dashed #000;
}
.metamorphosis {
  position: relative;

  .splide__arrows {

  }

  .splide__arrow--prev {
    top: 50%;
    left: 0;
    transform: translateY(-50%);
  }

  .splide__arrow--next {
    top: 50%;
    right: 0;
    transform: translateY(-50%);
  }

  &__slider-slide {
    width: 100%;
  }

  &__splide-slide {
    padding: 0 45px;
    display: flex;
    align-items: center;
  }

  .splide__arrow--prev, .splide__arrow--next {
    border: none;
    background-color: transparent;
    width: 29px;
    height: 29px;
    z-index: 10;
    position: absolute;
    padding: 0;
  }

  &__arrow {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .splide-arrows {

  }
}
