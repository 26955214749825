*{
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
}
*, *:hover, *:focus{
  outline: none !important;
  box-sizing: border-box;
}
body, html{
  width: 100%;
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;
  font-size: 62.5%;
}

body{
  min-height: 100vh;
  font-family: 'Dosis', sans-serif;
  font-weight: 400;
  font-style: normal;
  background: $color-light;
  color: #26201F;
  line-height: 1.2;
}

main {
  width: 100%;
  min-height: 100vh;
  background: url("./assets/pattern.png") repeat;
  position: relative;
  font-size: 2.4rem;
  overflow: hidden;
}

h1.heading {
  position: relative;
  padding-bottom: 3rem;
  margin-bottom: 2rem;

  &::after {
    content: '';
    width: 100px;
    height: 10px;
    border-radius: 10px;
    background-color: $color-red;
    position: absolute;
    bottom: 0;
    left: 0;
  }
}

.text-error {
  @extend .f16;
  color: $color-red;
  font-weight: 700;
  margin-top: -20px;
}

.f16 {
  font-size: 1.6rem;

  @media screen and (max-width: $lg) {
    font-size: 1.6rem;
  }

  @media screen and (max-width: $md) {
    font-size: 1.4rem;
  }
}

.f24 {
  font-size: 2.4rem;

  @media screen and (max-width: $lg) {
    font-size: 2.2rem;
  }

  @media screen and (max-width: $md) {
    font-size: 2rem;
  }

  @media screen and (max-width: $sm) {
    font-size: 1.6rem;
  }
}

.f27 {
  font-size: 2.7rem;

  @media screen and (max-width: $lg) {
    font-size: 2.2rem;
  }

  @media screen and (max-width: $md) {
    font-size: 2rem;
  }

  @media screen and (max-width: $sm) {
    font-size: 1.8rem;
  }
}

.f28 {
  font-size: 2.8rem;

  @media screen and (max-width: $lg) {
    font-size: 2.4rem;
  }

  @media screen and (max-width: $md) {
    font-size: 2.2rem;
  }

  @media screen and (max-width: $sm) {
    font-size: 1.8rem;
  }
}

.f32 {
  font-size: 3.2rem;

  @media screen and (max-width: $lg) {
    font-size: 3rem;
  }

  @media screen and (max-width: $md) {
    font-size: 2.6rem;
  }

  @media screen and (max-width: $sm) {
    font-size: 2.2rem;
  }
}

.f57 {
  font-size: 5.7rem;

  @media screen and (max-width: $lg) {
    font-size: 5rem;
  }

  @media screen and (max-width: $md) {
    font-size: 4.2rem;
  }

  @media screen and (max-width: $sm) {
    font-size: 3.2rem;
  }
}

.fw700 {
  font-weight: 700;
}

