.btn {
  @extend .f16;
  color: #fff;
  background: $color-orange;
  padding: 2rem 3.5rem;
  width: 100%;
  border-radius: 50px;
  border: 1px solid transparent;
  transition: all 0.3s;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    border: 1px solid $color-orange;
    background-color: #fff;
    color: $color-orange;
  }
}

//label {
//  display: block;
//  span {
//    position: relative;
//    width: 100%;
//
//    .input-divider {
//      width: 1px;
//      height: 100%;
//      background-color: #E1E1E1;
//      transform: translateY(-35%);
//      position: absolute;
//      top: 50%;
//      left: 55px;
//    }
//
//    img.input-icon {
//      position: absolute;
//      top: 50%;
//      left: 20px;
//      transform: translateY(-30%);
//    }
//
//    input, textarea {
//      width: 100%;
//      border-radius: 50px;
//      border: 1px solid $color-orange;
//      background-color: #fff;
//      color: #979797;
//      padding: 2rem 1.5rem 2rem 7rem;
//
//      @extend .f16;
//    }
//
//    textarea {
//      border-radius: 20px;
//      padding: 2rem 3.5rem;
//    }
//  }
//}