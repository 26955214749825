.contact {
  &__form {
    max-width: 500px;
    width: 100%;
    margin: 0 auto;
  }

  &__label {
    width: 100%;
    span {
      display: block;
      position: relative;
      width: 100%;

      &::before {
        display: inline-block;
        position: absolute;
        content: '';
        width: 21px;
        height: 21px;
        top: 50%;
        left: 15px;
        transform: translateY(-50%);
        z-index: 1;
      }
      &.name::before {
        background: url('./assets/icon-name.svg') no-repeat;
      }
      &.email::before {
        background: url('./assets/icon-massage.svg') no-repeat;
        height: 17px;
      }
      &::after {
        display: inline-block;
        position: absolute;
        content: '';
        background-color: #E1E1E1;
        width: 1px;
        height: 50%;
        top: 50%;
        left: 50px;
        transform: translateY(-50%);
        z-index: 1;
      }

      &.your-message{
        &::before {
          content: none;
        }

        &::after {
          content: none
        }
      }
    }
  }

  &__input {
    position: relative;
    width: 100%;
    border-radius: 30px;
    border: 1px solid $color-orange;
    height: 60px;
    background-color: #fff;
    padding: 10px 35px 10px 65px;
    font-size: 16px;
  }

  &__text-area {
    position: relative;
    width: 100%;
    border-radius: 20px;
    border: 1px solid $color-orange;
    background-color: #fff;
    padding: 20px 35px 10px 65px;
    font-size: 16px;

  }
}