.main {
    position: relative;

    &__content {
      min-height: 100vh;
      width: 73vw;
      overflow-y: auto;
      padding: 90px 130px 0 130px;

      @media screen and (max-width: $xl) {
        width: 73vw;
        padding: 50px 70px 0 50px;
      }

      @media screen and (max-width: $lg) {
        width: 100vw;
        padding: 40px 50px 0 50px;
      }

      @media screen and (max-width: $sm) {
        padding: 40px 15px 0 15px;
      }
    }

    &__logo-container {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100vh;

      img {
        width: 100% !important;
        max-width: 869px !important;
        height: 100% !important;
      }
    }
}